<script lang="ts" setup>
const i18nHead = useLocaleHead({ addSeoAttributes: true })

useHead({
  title: () => `${$t('yPPSdb3necWCi2jXy_ECS')} - ${$t('ZtzqRdD1HHSKd7-9mLEPS')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
    { name: 'description', content: () => `${$t('yIx3M1U0v8TFjoVJ8onwl')}，${$t('va2l6qnwD615aEMKBU2JQ')}` },
  ],
})
</script>

<template>
  <article class="feq-root">
    <div class="feq-content page-content-width">
      <h1>{{ $t('yPPSdb3necWCi2jXy_ECS') }}</h1>
      <p>{{ $t('yIx3M1U0v8TFjoVJ8onwl') }}</p>
      <p>{{ $t('_YEsmbpGX5MtOEDKfoYcU') }}</p>
      <p>{{ $t('mgja7cSdWhrYZ4dTk4CGX') }}</p>
      <p>{{ $t('81fo_hLbH8vwn91ucREIo') }}</p>
      <p>{{ $t('rNCFQp1a88VyvcrCktw1H') }}</p>
      <p>{{ $t('XRndXD1cELrPagz_p93eG') }}</p>
      <p>{{ $t('_RzN-L3KrISMyYtePQa8H') }}</p>
      <p>{{ $t('KwlWi0_HtEW2gr5F77Wvb') }}</p>
      <p>{{ $t('hujUbvCZe_z6Qi7_PVV80') }}</p>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import './style/help-content-page';
</style>
